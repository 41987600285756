.sol-social-links {
  text-align: left;
  font: normal normal normal 18px/30px Arial;
  letter-spacing: 0;
  color: #000;

  a {
    text-align: left;
    font: normal normal bold 20px/28px "TradeGothicLT-Light";
    letter-spacing: 0;
    color: #000;
    margin-right: 10px;

    &:hover {
      text-decoration: underline;
    }
  }

  i.fab {
    font-size: 25px;
  }
}
