.sol-to-content {
  position: absolute;
  top: 10px;
  left: 25%;
  width: 0;
  padding: 5px;
}

.sol-to-content:focus {
  outline: white solid 2px;
  z-index: 10;
  background-color: #e6b350;
  color: black;
  width: 150px;
}
