.sol-top-image {
  .pagecontent {
    .sv-text-portlet {
      position: absolute;
      right: 0;
    }
    .sv-vertical.sv-layout {
      background-position: center;
      height: 720px;
      min-height: unset;
      //margin-left: 10px;
      @media (max-width: 767px) {
        height: 350px;
      }
    }
  }
}

p.normal.sv-text-align-right {
  em {
    font-size: 16px !important;
  }
}

.sv-portlet-image-caption {
  font-size: 16px !important;
}
.sol-gra-textruta2 {
  margin-left: 0 !important;
}
