a,
p.normal a,
p a {
  color: #76014c;
  text-decoration: none;
}


h1,
h2,
h3 {
  font-family: "TrimPosterWebExtraCompressed" !important;
  font-weight: normal !important;
}

h1.heading {
  padding: 0.1em 0;
}

ul,
ol {
  padding-left: 15px !important;
}

ul li,
ol li {
  padding-bottom: 5px !important;
}

label {
  font-family: verdana, sans-serif;
  font-weight: bold;
  font-style: normal;
  font-size: 1.5em;
  color: #000;
  line-height: 1.25em;
}

h1 a,
h2 a,
h3 a {
  font-family: "TrimPosterWebExtraCompressed" !important;
  font-weight: normal !important;
  text-decoration: none;
  display: block;
}

h1 a:hover,
h2 a:hover,
h3 a:hover {
  opacity: 0.9;
  display: block;
}

.sv-dbform-portlet * {
  float: none;
  width: 100%;
  margin: 0;
  padding: 0;
}

.sv-dbform-portlet > div {
  /* display: none; */
}

.sv-dbform-portlet .sv-form-textfield,
.sv-dbform-portlet input[type=text],
.sv-dbform-portlet textarea {
  width: 100% !important;

  /* background: #ddd !important;
  border: none !important; */
  font-family: "TradeGothicLT-Light" !important;
  padding: 0.3em 0.1em 0.1em !important;
  margin-bottom: 1em;
}

.sv-dbform-portlet input[type=submit] {
  width: auto;
}

.sv-dbform-portlet .ielayoutfix label {
  font-family: "TrimPosterWebExtraCompressed" !important;
  font-size: 2em !important;
}

.sv-dbform-portlet .ielayoutfix {
  border: none !important;
  background: none !important;
  padding: none !important;
}

p.normal {
  margin-bottom: 1em;
}

.sv-jcrmenu-portlet ul li a,
.ingress,
.sv-jcrmenu-portlet ul li a:hover,
.footerrubrik {
  font-family: "TradeGothicLT-BoldTwo";
}

.lighttext,
.lighttextWhite {
  font-family: "TradeGothicLT-Light";
}

.footertext a {
  font-family: "TradeGothicLT-Light";
  font-size: 1em;
  line-height: 1.357em;
  margin-bottom: 0.5em;
}

.container {
  max-width: 1040px;
  margin: 0 auto;
}

.sv-fluid-grid {
  padding: 2em 0;
}

nav {
  background: #000;
  min-height: 50px;
}

.centered-on-page {
  margin: 200px auto 0 auto;
  max-width: 500px;
  padding: 20px;
  background: #000;
  color: #fff;
  border-radius: 10px;
  text-align: center;
}

.centered-on-page h1,
.centered-on-page h2,
.centered-on-page p.normal {
  color: #fff;
}

.newsrow img,
.sv-channel .sv-channel-item a img {
  max-height: none;
  max-width: 100%;
  height: auto !important;
}

.facebook,
.instagram,
.twitter {
  font-size: 0;
  margin: 2.5px;
  padding: 0 3px 0 0;
  cursor: pointer;
}

.twitter {
  &::after {
    @include icon($fa-var-twitter-square, 18px, none, brand);
  }
}

.instagram {
  &::after {
    @include icon($fa-var-instagram, 18px, none, brand);
  }
}

.facebook {
  &::after {
    @include icon($fa-var-facebook-square, 18px, none, brand);
  }
}

.sv-channel .sv-channel-item > a {
  display: block;
  padding-top: 10px;
}

.sv-channel .sv-channel-item strong {
  font-weight: normal !important;
}

/* THX SV */
span.subheading {
  display: none;
}

.swiper-container {
  padding: 25px 0;
  max-height: 315px;
}

.arrows {
  float: right;
  position: relative;
  top: -37px;
  cursor: pointer;
}

.arrows div {
  float: left;
}

.arrows div img {
  width: 36px;
}

.arrows > div:first-child {
  float: left;
  margin-right: 5px;
}

.submenu,
#svid12_6b8436241481cd831c233a7 {
  border-bottom: 1px solid #bfbfbf !important;
  padding-bottom: 0.5em !important;
  margin-bottom: 1em !important;
}

.submenu ul {
  padding-left: 0 !important;
}

.submenu ul li {
  padding-right: 5px;
}

.submenu ul li span {
  display: none;
}

.submenu a {
  font-size: 0.875em !important;
  line-height: 1.571em !important;
}

.scrollArrow img {
  display: block;
  margin: 0 auto;
  margin-top: 50px;
  cursor: pointer;
}

.prata a {
  font-family: "TradeGothicLT-BoldTwo";
  text-decoration: none;
}

.prata a img.sv-linkicon,
p.normalxvit strong a img.sv-linkicon {
  display: none !important;
}

#svid10_6b8436241481cd831c2814,
#svid12_6b8436241481cd831c2817 {
  padding: 0 !important;
}

.sv-portlet-image-caption {
  font-family: arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  color: #000;
  line-height: 1.5em;
  padding-top: 0.3em;
}

.startImage img {
  width: 100%;
  max-width: none;
  height: auto;
  max-height: none;
}


abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video { margin: 0; padding: 0; border: 0; outline: 0; font-size: 100%; vertical-align: baseline; background: 0 0; }
body { line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section { display: block; }
nav ul { list-style: none; }

blockquote,
q { quotes: none; }

blockquote::after,
blockquote::before,
q::after,
q::before { content: ''; content: none; }
a { margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: 0 0; }
ins { background-color: #ff9; color: #000; text-decoration: none; }
mark { background-color: #ff9; color: #000; font-style: italic; font-weight: 700; }
del { text-decoration: line-through; }

abbr[title],
dfn[title] { border-bottom: 1px dotted; cursor: help; }
table { border-collapse: collapse; border-spacing: 0; }
hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }

input,
select { vertical-align: middle; }

/*****************************
 ADDITION SOLEIL BLOG 2015
*****************************/

.sol-blog-top-container {
  background-size: cover;
  padding-top: 130px;
  padding-bottom: 35px;
  margin-bottom: 90px;
}

.sol-blog-top-container h1 {
  font-size: 150px;
  line-height: 125px;
}

.sol-blog-module,
.sol-blog-content {
  margin-bottom: 60px;
}

.sol-blog-module .sv-blog-item {
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 85px !important;
  margin-bottom: 20px;
}

.sol-blog-module h2,
.sol-blog-module h3,
.sol-blog-content h2.subheading,
.sol-blog-content h3.sol-writer-title {
  padding-top: 0;
  padding-bottom: 20px;
}

.sol-blog-module h2 > a {
  display: inline-block;
}

.sol-blog-module h3,
.sol-blog-content .sol-writer-title {
  color: #000;
}

.sol-blog-module iframe,
.sol-blog-content iframe {
  margin-bottom: 15px;
  max-width: 100%;
}

.sol-blog-module .sol-date-cat > span,
.sol-blog-content .sol-date-cat > span {
  font-weight: bold;
  display: inline-block;
  margin-right: 25px;
}

.sol-blog-module .sol-date-cat,
.sol-blog-content .sol-date-cat {
  margin-bottom: 15px;
}

.sol-blog-module img,
.sol-blog-content .sv-image-portlet {
  margin-bottom: 15px;
}

.sol-blog-module img,
.sol-blog-content img {
  display: block;
  max-width: 100%;
  height: inherit !important;
}

.sol-blog-module .ingress {
  display: block;
}

.sol-blog-module .sol-writer-name,
.sol-blog-module .sol-writer-info,
.sol-blog-content .sol-writer-name,
.sol-blog-content .sol-writer-info {
  display: block;
  margin: 0;
  line-height: 1.3em;
}

.sol-blog-module .sol-writer-name,
.sol-blog-content .sol-writer-name {
  font-weight: normal;
}

.sol-blog-module .sol-writer-info,
.sol-blog-content .sol-writer-info {
  margin-bottom: 40px;
}

.sol-blog-module > div:last-child {
  text-align: center;
}

.sol-blog-module > div:last-child > a {
  margin-left: 15px;
  margin-right: 15px;
}

.sol-blog-module .sv-column-3 > a.normal:last-child {
  margin-top: 10px;
  font-size: 17px;
  display: block;
  text-align: right;
  font-family: "TradeGothicLT-Light";
  font-size: 1.2em;
}

.sol-filter-blog > span {
  font-family: "TradeGothicLT-Light";
  font-size: 18px;
  font-weight: bold;
}

.sol-filter-blog ul {
  padding: 0 !important;
}

.sol-filter-blog ul > li .normal {
  font-family: "TradeGothicLT-Light";
  color: #862413;
  display: block;
  font-weight: bold;
}

.sol-filter-blog ul > li:first-child .normal {
  margin-top: 5px;
  margin-bottom: 5px;
}

.sol-filter-blog ul > li .normal.sol-curr-category {
  color: black;
}

.sol-blog-content p.sv-portlet-image-caption {
  /* font-weight: 700; */
}

.sol-blog-content p.sv-portlet-image-caption::before {
  content: 'Bildtext: ';
}

.sol-blog-content .sv-text-portlet-content p.lighttextWhite {
  margin-bottom: 1em;
}

.sol-lastest-list .sol-lastest-date,
.sol-lastest-list a.normal {
  font-family: "TradeGothicLT-Light";
  font-size: 16px;
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
}

.sol-lastest-list .sol-lastest-date {
  margin-top: 25px;
}

.sol-lastest-list .sol-lastest-date:first-child {
  margin-top: 0;
}

.sol-blog-module h3.sol-blog-header,
.sol-blog-content h3.sol-blog-header {
  font-family: "TradeGothicLT-BoldTwo" !important;
  font-size: 17px;
  margin-bottom: 5px;
  font-weight: bold !important;
  padding: 0;
  color: black;
}

.sol-blog-module .sv-text-portlet-content p {
  margin-top: 0;
  margin-bottom: 1em;
}

.sol-blog-module .sv-text-portlet-content > *:last-child {
  margin-bottom: 0;
}

.sol-blog-startpage {
  padding-top: 35px;
}

.sol-blog-startpage * {
  box-sizing: border-box;
}

.sol-blog-startpage .sv-fluid-grid > div {
  width: 50%;
  float: left;
}

.sol-blog-startpage .sv-fluid-grid > div:first-child {
  padding-right: 0.55999999%;
}

.sol-blog-startpage .sv-fluid-grid > div:last-child {
  padding-left: 0.55999999%;
}

.sol-blog-startpage .sol-blog-startpage-swipe {
  padding: 0;
  position: relative;
}

.sol-blog-startpage .sol-blog-startpage-swipe .content-slide {
  padding-right: 15px;
  padding-left: 5px;
}

.sol-blog-startpage .sol-blog-startpage-swipe .arrows {
  position: absolute;
  bottom: 0;
  right: 0;
  top: inherit;
  float: none;
}

.sol-blog-startpage .sol-blog-startpage-swipe .sol-blog-start-title {
  font-family: "TradeGothicLT-BoldTwo";
  font-weight: 500;
  color: black;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0;
  text-transform: uppercase;
}

.sol-blog-startpage .sol-blog-startpage-swipe .sol-blog-start-date {
  font-family: "Arial";
  font-weight: normal;
  color: black;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
}

.sol-blog-startpage .sol-blog-startpage-swipe .sol-blog-start-desc {
  margin-top: 46px;
  color: black;
  line-height: 30px;
  font: normal normal normal 18px/30px Arial;
}
