.sol-program-item {
  cursor: pointer;
  margin: 20px 15px;
  height: 100%;
  background: white 0% 0% no-repeat padding-box;
  position: relative;
  display: flex;
  padding-bottom: 40px;
  border-bottom: 2px solid #c1beaf75;

  &-content {
    margin: 0;
    margin-left: 35px;
    padding: 0 0 45px 0;
    position: relative;
  }

  &-date {
    position: absolute;
    left: 0;
    top: 15px;
    background-color: #896dd0;
    color: white;
    padding: 10px 14px;
    font-family: 'TrimPosterWebExtraCompressed', Arial, Helvetica, sans-serif;
    font-size: 26px;
  }

  &-img {
    padding: 0;
    max-width: 100%;
    width: 300px;
    height: 210px;
    background-size: cover;

    &-container {
      position: relative;
    }

    &-text {
      background: #896dd0 0% 0% no-repeat padding-box;
      text-align: left;
      font: normal normal normal 26px/40px 'TrimPosterWebExtraCompressed';
      letter-spacing: 0;
      color: #fff;
      position: absolute;
      left: 0;
      top: 10%;
      padding: 10px 14px;
      font-weight: bold;
    }
  }

  &-heading {
    a {
      text-align: left;
      font-family: 'TradeGothicLT-BoldTwo' !important;
      font-size: 22px;
      line-height: 1.3;
      letter-spacing: 0;
      color: #000;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
  }

  &-desc {
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    font-family: 'TradeGothicLT-Light';
    letter-spacing: 0;
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &-text-content {
    line-height: 1.5;
  }

  &:hover {
    text-decoration: underline;
  }

  .sol-action-link {
    font-family: "TradeGothicLT-Light";
    background-color: #fae2de;
    display: inline-block;
    padding: 15px 15px 15px 25px;
    font-weight: bold;
    font-size: 16px;
    transition-duration: 200ms;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .sol-action-link:hover {
    background-color: #f3b7ad;
    text-decoration: underline;
  }

  .sol-action-link i {
    margin-left: 15px;
  }
}

.newsrow .sv-archive-portlet ul {
  margin: -15px -30px;
  display: flex;
  flex-wrap: wrap;
}

.newsrow .sv-archive-portlet li {
  width: 33%;
  display: block;
  flex: 0 0 auto;
  min-width: 300px;
  margin-bottom: 35px;
}

@media screen and (max-width: 1200px) {
  .newsrow .sv-archive-portlet li {
    width: 49%;
  }

  .sol-program-item {
    flex-direction: column;
    padding-bottom: 0;

    &-content {
      margin-left: 0;
      margin-top: 10px;
    }

    &-img {
      width: 100%;
    }

    .sol-action-link {
      position: relative;
      margin-top: 15px;
    }
  }
}

@media screen and (max-width: 767px) {
  .newsrow .sv-archive-portlet li {
    width: 100%;
  }
}

.newsrow .sv-archive-portlet li h2 a {
  font-family: verdana, sans-serif !important;
  font-weight: 600 !important;
  line-height: 1.3;
}

p.sv-portlet-image-caption {
  font-size: 18px;
  font-style: italic;
  margin-top: 10px;
  position: absolute;
  right: 0;
}

@media screen and (max-width: 1024px) {
  p.sv-portlet-image-caption {
    font-size: 18px;
    font-style: italic;
    margin-top: 10px;
    position: relative;
    right: 0;
  }
}

.sv-image-portlet {
  position: relative;
}
