@mixin fal() {
  font-family: 'Font Awesome 5 Pro', sans-serif;
  font-weight: 300;
}

@mixin far() {
  font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free', sans-serif;
  font-weight: 400;
}

@mixin fas() {
  font-family: 'Font Awesome 5 Pro', 'Font Awesome 5 Free', sans-serif;
  font-weight: 900;
}

@mixin fab() {
  font-family: 'Font Awesome 5 Brands', sans-serif;
}

@mixin icon($icon, $size: inherit, $position: after, $style: regular) {
  display: inline-block;
  font-size: $size;

  @if $style == regular {
    @include far;
  }
  @else if $style == solid {
    @include fas;
  }
  @else if $style == light {
    @include fal;
  }
  @else if $style == brand {
    @include fab;
  }

  @if $position == after {
    &::after {
      content: fa-content($icon);
    }
  }
  @else if $position == before {
    &::before {
      content: fa-content($icon);
    }
  }
  @else {
    content: fa-content($icon);
  }
}
