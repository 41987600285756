.sol-article-item {
  cursor: pointer;
  margin: 20px 15px;
  height: 100%;
  background: #c1beaf33 0% 0% no-repeat padding-box;
  position: relative;

  &-content {
    margin: 0;
    padding: 34px 26px 45px 31px;
  }

  &-img {
    padding: 0;
    max-width: 100%;
    width: 100%;
    height: auto;

    &-container {
      position: relative;
    }

    &-text {
      background: #896dd0 0% 0% no-repeat padding-box;
      text-align: left;
      font: normal normal normal 20px/40px 'TradeGothicLT-BoldTwo';
      letter-spacing: 0;
      color: #fff;
      position: absolute;
      left: 0;
      top: 10%;
      padding: 10px 14px;
    }
  }

  &-heading {
    text-align: left;
    font: normal normal bold 20px/26px 'TradeGothicLT-Light';
    letter-spacing: 0;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  &-desc {
    text-align: left;
    font: normal normal normal 16px/22px 'TradeGothicLT-Light';
    letter-spacing: 0;
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &-text-content {
    line-height: 1.5;
  }

  &:hover {
    text-decoration: underline;
  }

  i {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}

.newsrow .sv-archive-portlet ul {
  margin: -15px -30px;
  display: flex;
  flex-wrap: wrap;
}

.newsrow .sv-archive-portlet li {
  width: 33%;
  display: block;
  flex: 0 0 auto;
  min-width: 300px;
  margin-bottom: 35px;
}

@media screen and (max-width: 1200px) {
  .newsrow .sv-archive-portlet li {
    width: 49%;
  }
}

@media screen and (max-width: 767px) {
  .newsrow .sv-archive-portlet li {
    width: 100%;
  }
}

.newsrow .sv-archive-portlet li h2 a {
  font-family: verdana, sans-serif !important;
  font-weight: 600 !important;
  line-height: 1.3;
}

.sv-fluid-grid {
  position: relative; 
}

.sol-date-tag {
  position: absolute;
  left: 0;
  top: 85px;
  letter-spacing: 1px;
  background-color: #896dd0;
  color: white;
  padding: 10px 14px;
  font-family: 'TrimPosterWebExtraCompressed', Arial, Helvetica, sans-serif;
  font-size: 26px;
}
