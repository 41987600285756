@keyframes bubbleDesktopAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bubbleDesktopAnimation {
  @media (min-width: 992px) {
    animation: bubbleDesktopAnimation 2s 0s ease;
    animation-fill-mode: forwards;
  }
}

.kh-bubble-cnt {
  @media (min-width: 992px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1410px;
    width: calc(100% - 107px);
    top: 200px;
  }

  @media (min-width: 1200px) {
    top: 170px;
  }
}

.kh-text-in-bubble {
  width: 165%;
  height: 275px;
  padding-left: 40%;
  padding-right: 40%;
  position: absolute;
  left: 50%;
  display: flex;
  align-items: center; // Gör att texten ligger överst i layoutstacken (över :before och :after)
  transform: translate(-50%, -90%);

  > .sv-layout {
    z-index: 9;

    @media (min-width: 992px) {
      width: 80%;
    }
  }

  .pagecontent {
    @media (min-width: 992px) {
      transform: scale(0);
      transition: all 0.3s ease;
    }
  }

  &--visible {
    .pagecontent {
      transform: scale(1);
    }
  }

  @media (min-width: 992px) {
    -webkit-transform: none;
    transform: none;
    position: absolute;
    padding-left: 0;
    padding-right: 0;
    border-radius: 50%;
    width: 40vw;
    height: 40vw;
    min-width: 400px;
    min-height: 400px;
    max-width: 570px;
    max-height: 570px;
    right: 0;
    left: auto;
    z-index: 99999;
  }

  .kh-button--medium {
    font-size: 12px;

    @media (min-width: 992px) {
      font-size: 14px;
    }
  }

  .kh-button {
    margin-bottom: 0;
  }

  > div {
    margin-left: auto;
    margin-right: auto;
  }

  &::after {
    content: "";
    background: #e6b350;
    position: absolute;
    left: 0;
    right: 0;
    top: -7px;
    bottom: 4px;
    z-index: 1;

    @media (min-width: 992px) {
      display: block; // TODO
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background: #e6b350;
      border-radius: 50%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin-top: 0;
    padding-top: 0;
    text-align: center;
    width: 100%;
  }

  p.normal {
    margin-top: 10px;
  }

  .lighttext {
    margin-bottom: 15px;
  }

  .sv-text-portlet {
    width: 100%;

    p {
      a {
        color: black;
        background: #fff;
        border-radius: 55px;
        padding: 15px 25px;
      }
    }
  }

  .sv-decoration-content {
    .pagecontent {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

html {
  scroll-behavior: smooth;
}
