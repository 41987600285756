.sol-top-menu {
  padding-top: 0;
  padding-bottom: 0;
  font-weight: bold;
  width: 90%;
  margin: auto;

  ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 60px;

    li {
      height: 100%;
      padding-bottom: 0 !important;

      a {
        font-size: 16px !important;
        height: 100%;
        width: 100%;
        color: black;
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1200px) {
          font-size: 14px !important;
          padding: 0 10px;
        }

        &:hover {
          color: #862413;
        }

        &.menyx2 {
          color: #862413 !important;
          background-color: #e76f5b1a;
        }
      }
    }
  }

  .sv-jcrmenu-portlet {
    border-bottom: none !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}
