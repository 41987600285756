* {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
  width: 100%;
  position: relative;
  height: 100%;
}

.kh-top-bg-image {
  background-image: url('/images/18.2a0514cf167c6092224692fe/1550224204324/Toppbild-600x404.jpeg');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.vp-controls-wrapper {
  display: none;
}

.kh-media-wrapper {
  width: 100%;
  overflow: hidden;
  height: 500px;
  background-color: #fff;
  position: relative;

  .kh-hero {
    height: auto;
    min-width: 100%;
    min-height: 56.25vw;
    width: 80vh;

    @media (min-width: 992px) {
      width: 100vw;
    }
  }

  @media (min-width: 992px) {
    height: 700px;
    position: relative;
    max-height: 960px;

    svg {
      position: absolute;
      bottom: 0;
      height: 100px;
    }
  }

  .kh-play-button {
    cursor: pointer;

    &__cnt {
      position: absolute;
      top: 210px;
      left: 50%;
      transform: translateX(-50%);

      @media (min-width: 992px) {
        bottom: 30%;
        position: absolute;
        top: 0 !important;
      }
    }

    @media (min-width: 992px) {
      height: 55px;
      font-size: 14px;
      background: black;
      border: 2px solid white;
      border-top: none;
    }

    width: 160px;
    height: 45px;
    font-size: 12px;
    z-index: 99;
    background: #e6b350;
    color: #fff;
    padding: 2px;
    border: none;
    i { margin-right: 5px; }

    & + i {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
